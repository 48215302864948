<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div>
      <div class="mainauto">
        <div class="mainTop">
          <div class="mainTitle">关于我们</div>
          <div class="content">
            南京灵育信息是一家拥有云计算技术和知识产权的科技型企业，汇聚了来自惠普、西门子、拉手网、
            富士康科技集团等厂商顶尖的云计算、大数据及AI人才。
          </div>
          <div class="content">
            公司的创始团队来源于北京明晓信息技术公司的核心高管，北京明晓于2014年5月成立，为企业，政府、教育等行业提供软件咨询，定制化服务、行业解决方案，耕耘多年，积累了大量的前沿技术、培
            养一批优秀的团队。
          </div>
          <div class="content">
            灵育信息凭借多年的沉淀、高起点的优势致力于领先的云计算大数据及AI领域，聚焦于资产设备运营管理平台、智慧物业管理平台，智慧校园平台等产品方案，并提供针对用户需求的定制研发服务解决
            方案，助力于企业、教育等行业，进行业务拓展与创新。
          </div>
          <div class="content">
            案例遍布于全国各地，主要客户有国家电网、中国石化集团、陆军炮兵学院、陆军步兵学院、安徽武
            警、中国联通山西省分公司、山西税务局、南通产控集团等。
          </div>
          <div class="content">
            <img src="@/assets/aboutus/us_img1.png" alt="" />
          </div>
        </div>
        <div class="mainBottom">
          <div class="mainTitle">我们的团队</div>
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="5"
              ><div class="grid-content bg-purple">
                <div class="number">1</div>
                <div class="personnel">郝晓亮<br /><span>博士</span></div>
                <div class="arrow">
                  <img src="@/assets/aboutus/arrow.png" alt="" />
                </div>
                <div class="introduce">
                  <p>资深技术专家团队组成员:</p>
                  <br />
                  <p>
                    拥有20年的软件从业及管理经验，曾先后服务于中国无线电电子研究所、花旗集团惠普公司、拉手网等国内外知名公司。
                  </p>
                  <p>
                    担任多个万人月级别的大型项目技术顾问，参与并主导多个<strong>大数据及AI人工智能</strong>一线项目，实践经验丰富。
                  </p>
                </div>
              </div></el-col
            >
            <el-col :span="5"
              ><div class="grid-content bg-purple">
                <div class="number">2</div>
                <div class="personnel">李利明<br /><span>硕士</span></div>
                <div class="arrow">
                  <img src="@/assets/aboutus/arrow.png" alt="" />
                </div>
                <div class="introduce">
                  <p>资深技术专家团队组成员:</p>
                  <br />
                  <p>
                    从事软件研发行业20年，曾先后服务于西门子，富士康集团等国内外知名公司。
                  </p>
                  <p>
                    精通多种开发语言和平台，擅长架构设计和项目管理;负责过多个大型项目如<strong>视频会议系统、分布式存储系统楼宇消防控制系统、机房环境监控系统等</strong>的架构设计、项目管理和开发。
                  </p>
                </div>
              </div></el-col
            >
            <el-col :span="5"
              ><div class="grid-content bg-purple">
                <div class="number">3</div>
                <div class="personnel">冯波<br /><span>架构师</span></div>
                <div class="arrow">
                  <img src="@/assets/aboutus/arrow.png" alt="" />
                </div>
                <div class="introduce">
                  <p>资深系统架构师，平台研发高级专家:</p>
                  <br />
                  <p>
                    从事互联网相关平台研发研究十余年，先后供职神州数码、国家电网公司、拉手网等知名企业;拥有丰富的产品设计研发经验;<strong>尤其对高并发、高可用、高性能平台应用的设计与研发</strong>有深刻的理解。
                  </p>
                </div>
              </div></el-col
            >
            <el-col :span="5"
              ><div class="grid-content bg-purple">
                <div class="number">4</div>
                <div class="personnel">李可<br /><span>项目管理师</span></div>
                <div class="arrow">
                  <img src="@/assets/aboutus/arrow.png" alt="" />
                </div>
                <div class="introduce">
                  <p>系统集成项目管理师:</p>
                  <br />
                  <p>
                    拥有10多年的软硬件从业及管理经验，曾先后服务于富士康科技集团等国内外知名公司﹔
                  </p>
                  <p>
                    <strong>担任多个干万级别信息化集成项目的管理工作</strong
                    >，特别在<strong>教育信息化，企业信息化</strong>等行业领域实践经验丰富。
                  </p>
                </div>
              </div></el-col
            >
          </el-row>
          <div class="mainTitle">公司软件著作权</div>
          <div class="certificate">
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu1.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple-light">
                  <img src="@/assets/aboutus/zhengshu2.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu3.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu4.png" alt="" /></div
              ></el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu5.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple-light">
                  <img src="@/assets/aboutus/zhengshu6.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu7.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu8.png" alt="" /></div
              ></el-col>
            </el-row>
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu9.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple-light">
                  <img src="@/assets/aboutus/zhengshu10.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu11.png" alt="" /></div
              ></el-col>
              <el-col :span="5"
                ><div class="grid-content bg-purple">
                  <img src="@/assets/aboutus/zhengshu12.png" alt="" /></div
              ></el-col>
            </el-row>
          </div>
           <div class="mainTitle">公司资质</div>
           <div class="qualifications">
             <img src="../../assets/aboutus/zizhi.png" alt="">
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-us",
  data() {
    return {
      bglist: [{ id: 0, url: require("@/assets/aboutus/us_bg2.png") }],
    };
  },
};
</script>

<style scoped>
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.img {
  width: 100%;
  height: 100%;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 56px 0 180px;
}
.mainTitle {
  font-size: 36px;
  color: #333;
  margin-bottom: 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 78px;
  height: 8px;
z-index: -1;
  background-color: #aeccf8;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 32px;
  letter-spacing: 0.8px;
}
.content img {
  width: 100%;
}
.certificateBox {
  width: 100%;
  padding: 68px 0 100px;
}
.certificateBox img {
  width: calc(100% / 4);
}
.teamBox {
  display: flex;
  justify-content: space-around;
  padding: 0 90px;
}
.teamBox img {
  width: 420px;
  height: 422px;
}

.number {
  width: 50px;
  height: 50px;
  background-color: #0a478a;
  margin: 22px auto 56px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  line-height: 50px;
  margin-bottom: 56px;
}
.personnel {
  width: 150px;
  height: 150px;
  background-color: #00b1c8;
  margin: 0 auto;
  border-radius: 50%;
  color: #fff;
  padding-top: 40px;
  text-align: center;
  font-size: 26px;
  margin-bottom: 26px;
}
.arrow img {
  width: 40px;
}
.introduce {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.2px;
  margin-top: 16px;
  color: #5f5f5f;
}
.introduce strong {
  color: #333;
}
.row-bg {
  margin-bottom: 30px;
}
.certificate .row-bg img {
  width: 100%;
}
.qualifications img{width:530px;}
</style>